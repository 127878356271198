export const currencies = [
  {
    code: 'USD',
    name: 'United States Dollar',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/USD.png',
    countryCode: 'US',
  },
  {
    code: 'AUD',
    name: 'Australian Dollar',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/AUD.png',
    countryCode: 'AU',
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/CAD.png',
    countryCode: 'CA',
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
    symbol: 'Fr',
    flag: 'https://assets.workparallel.com/remote/flags/CHF.png',
    countryCode: 'CH',
  },
  {
    code: 'GBP',
    name: 'Pound Sterling',
    symbol: '£',
    flag: 'https://assets.workparallel.com/remote/flags/GBP.png',
    countryCode: 'GB',
  },
  {
    code: 'EUR',
    name: 'Euro',
    symbol: '€',
    flag: 'https://assets.workparallel.com/remote/flags/EUR.png',
    countryCode: null,
  },
  {
    code: 'ZAR',
    name: 'South African Rand',
    symbol: 'R',
    flag: 'https://assets.workparallel.com/remote/flags/ZAR.png',
    countryCode: 'ZA',
  },
  {
    code: 'JPY',
    name: 'Japanese Yen',
    symbol: '¥',
    flag: 'https://assets.workparallel.com/remote/flags/JPY.png',
    countryCode: 'JP',
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/NZD.png',
    countryCode: 'NZ',
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/SGD.png',
    countryCode: 'SG',
  },
  {
    code: 'RUB',
    name: 'Russian Ruble',
    symbol: '₽',
    flag: 'https://assets.workparallel.com/remote/flags/RUB.png',
    countryCode: 'RU',
  },
  {
    code: 'DKK',
    name: 'Danish Krone',
    symbol: 'kr',
    flag: 'https://assets.workparallel.com/remote/flags/DKK.png',
    countryCode: 'DK',
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/HKD.png',
    countryCode: 'HK',
  },
  {
    code: 'ILS',
    name: 'Israeli New Shekel',
    symbol: '₪',
    flag: 'https://assets.workparallel.com/remote/flags/ILS.png',
    countryCode: 'IL',
  },
  {
    code: 'INR',
    name: 'Indian Rupee',
    symbol: '₹',
    flag: 'https://assets.workparallel.com/remote/flags/INR.png',
    countryCode: 'IN',
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
    symbol: '₺',
    flag: 'https://assets.workparallel.com/remote/flags/TRY.png',
    countryCode: 'TR',
  },
  {
    code: 'XCD',
    name: 'East Caribbean Dollar',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/XCD.png',
    countryCode: null,
  },
  {
    code: 'CNY',
    name: 'Chinese Yuan',
    symbol: '¥',
    flag: 'https://assets.workparallel.com/remote/flags/CNY.png',
    countryCode: 'CN',
  },
  {
    code: 'BND',
    name: 'Brunei Dollar',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/BND.png',
    countryCode: 'BN',
  },
  {
    code: 'FKP',
    name: 'Falkland Islands Pound',
    symbol: '£',
    flag: 'https://assets.workparallel.com/remote/flags/FKP.png',
    countryCode: 'FK',
  },
  {
    code: 'JOD',
    name: 'Jordanian Dinar',
    symbol: 'JD',
    flag: 'https://assets.workparallel.com/remote/flags/JOD.png',
    countryCode: 'JO',
  },
  {
    code: 'ANG',
    name: 'Netherlands Antillean Guilder',
    symbol: 'NAƒ',
    flag: 'https://assets.workparallel.com/remote/flags/ANG.png',
    countryCode: null,
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/MXN.png',
    countryCode: 'MX',
  },
  {
    code: 'THB',
    name: 'Thai Baht',
    symbol: '฿',
    flag: 'https://assets.workparallel.com/remote/flags/THB.png',
    countryCode: 'TH',
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone',
    symbol: 'kr',
    flag: 'https://assets.workparallel.com/remote/flags/NOK.png',
    countryCode: 'NO',
  },
  {
    code: 'PHP',
    name: 'Philippine Peso',
    symbol: '₱',
    flag: 'https://assets.workparallel.com/remote/flags/PHP.png',
    countryCode: 'PH',
  },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit',
    symbol: 'RM',
    flag: 'https://assets.workparallel.com/remote/flags/MYR.png',
    countryCode: 'MY',
  },
  {
    code: 'SEK',
    name: 'Swedish Krona',
    symbol: 'kr',
    flag: 'https://assets.workparallel.com/remote/flags/SEK.png',
    countryCode: 'SE',
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev',
    symbol: 'Лв',
    flag: 'https://assets.workparallel.com/remote/flags/BGN.png',
    countryCode: 'BG',
  },
  {
    code: 'COP',
    name: 'Colombian Peso',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/COP.png',
    countryCode: 'CO',
  },
  {
    code: 'RON',
    name: 'Romanian Leu',
    symbol: 'lei',
    flag: 'https://assets.workparallel.com/remote/flags/RON.png',
    countryCode: 'RO',
  },
  {
    code: 'IDR',
    name: 'Indonesian Rupiah',
    symbol: 'Rp',
    flag: 'https://assets.workparallel.com/remote/flags/IDR.png',
    countryCode: 'ID',
  },
  {
    code: 'AMD',
    name: 'Armenian Dram',
    symbol: '֏',
    flag: 'https://assets.workparallel.com/remote/flags/AMD.png',
    countryCode: 'AM',
  },
  {
    code: 'HUF',
    name: 'Hungarian Forint',
    symbol: 'Ft',
    flag: 'https://assets.workparallel.com/remote/flags/HUF.png',
    countryCode: 'HU',
  },
  {
    code: 'PLN',
    name: 'Polish Zloty',
    symbol: 'PLN',
    flag: 'https://assets.workparallel.com/remote/flags/PLN.png',
    countryCode: 'PL',
  },
  {
    code: 'CZK',
    name: 'Czech Koruna',
    symbol: 'Kč',
    flag: 'https://assets.workparallel.com/remote/flags/CZK.png',
    countryCode: 'CZ',
  },
  {
    code: 'KRW',
    name: 'South Korean Won',
    symbol: '₩',
    flag: 'https://assets.workparallel.com/remote/flags/KRW.png',
    countryCode: 'KR',
  },
  {
    code: 'CLP',
    name: 'Chilean Peso',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/CLP.png',
    countryCode: 'CL',
  },
  {
    code: 'XAF',
    name: 'Central African CFA Franc',
    symbol: 'Fr',
    flag: 'https://assets.workparallel.com/remote/flags/XAF.png',
    countryCode: null,
  },
  {
    code: 'MUR',
    name: 'Mauritian Rupee',
    symbol: '₨',
    flag: 'https://assets.workparallel.com/remote/flags/MUR.png',
    countryCode: 'MU',
  },
  {
    code: 'BRL',
    name: 'Brazilian real',
    symbol: 'R$',
    flag: 'https://assets.workparallel.com/remote/flags/BRL.png',
    countryCode: 'BR',
  },
  {
    code: 'XPF',
    name: 'CFP franc',
    symbol: 'Fr',
    flag: 'https://assets.workparallel.com/remote/flags/XPF.png',
    countryCode: null,
  },
  {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
    symbol: 'د.إ',
    flag: 'https://assets.workparallel.com/remote/flags/AED.png',
    countryCode: 'AE',
  },
  {
    code: 'TWD',
    name: 'New Taiwan Dollar',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/TWD.png',
    countryCode: 'TW',
  },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
    symbol: 'SR',
    flag: 'https://assets.workparallel.com/remote/flags/SAR.png',
    countryCode: 'SA',
  },
  {
    code: 'SHP',
    name: 'Saint Helena Pound',
    symbol: '£',
    flag: 'https://assets.workparallel.com/remote/flags/SHP.png',
    countryCode: 'SH',
  },
  {
    code: 'MAD',
    name: 'Moroccan Dirham',
    symbol: 'د.م',
    flag: 'https://assets.workparallel.com/remote/flags/MAD.png',
    countryCode: 'MA',
  },
  {
    code: 'ARS',
    name: 'Argentine Peso',
    symbol: '$',
    flag: 'https://assets.workparallel.com/remote/flags/ARS.png',
    countryCode: 'AR',
  },
  {
    code: 'PEN',
    name: 'Peruvian Sol',
    symbol: 'S/',
    flag: 'https://assets.workparallel.com/remote/flags/PEN.png',
    countryCode: 'PE',
  },
  {
    code: 'MNT',
    name: 'Mongolian Tugrik',
    symbol: '₮',
    flag: 'https://assets.workparallel.com/remote/flags/MNT.png',
    countryCode: 'MN',
  },
  {
    code: 'XOF',
    name: 'West African CFA Franc',
    symbol: 'Fr',
    flag: 'https://assets.workparallel.com/remote/flags/XOF.png',
    countryCode: null,
  },
] as const;

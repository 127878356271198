exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-affiliates-index-tsx": () => import("./../../../src/pages/affiliates/index.tsx" /* webpackChunkName: "component---src-pages-affiliates-index-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-bookademo-index-tsx": () => import("./../../../src/pages/bookademo/index.tsx" /* webpackChunkName: "component---src-pages-bookademo-index-tsx" */),
  "component---src-pages-bookademoform-index-tsx": () => import("./../../../src/pages/bookademoform/index.tsx" /* webpackChunkName: "component---src-pages-bookademoform-index-tsx" */),
  "component---src-pages-bookademoform-thankyou-tsx": () => import("./../../../src/pages/bookademoform/thankyou.tsx" /* webpackChunkName: "component---src-pages-bookademoform-thankyou-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-case-study-dr-clear-aligners-tsx": () => import("./../../../src/pages/case-study/dr-clear-aligners.tsx" /* webpackChunkName: "component---src-pages-case-study-dr-clear-aligners-tsx" */),
  "component---src-pages-case-study-index-tsx": () => import("./../../../src/pages/case-study/index.tsx" /* webpackChunkName: "component---src-pages-case-study-index-tsx" */),
  "component---src-pages-case-study-myria-tsx": () => import("./../../../src/pages/case-study/myria.tsx" /* webpackChunkName: "component---src-pages-case-study-myria-tsx" */),
  "component---src-pages-case-study-oscar-wylee-tsx": () => import("./../../../src/pages/case-study/oscar-wylee.tsx" /* webpackChunkName: "component---src-pages-case-study-oscar-wylee-tsx" */),
  "component---src-pages-case-study-soulara-tsx": () => import("./../../../src/pages/case-study/soulara.tsx" /* webpackChunkName: "component---src-pages-case-study-soulara-tsx" */),
  "component---src-pages-case-study-wondersmile-tsx": () => import("./../../../src/pages/case-study/wondersmile.tsx" /* webpackChunkName: "component---src-pages-case-study-wondersmile-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-index-tsx": () => import("./../../../src/pages/jobs/index.tsx" /* webpackChunkName: "component---src-pages-jobs-index-tsx" */),
  "component---src-pages-partnership-index-tsx": () => import("./../../../src/pages/partnership/index.tsx" /* webpackChunkName: "component---src-pages-partnership-index-tsx" */),
  "component---src-pages-relocation-service-index-tsx": () => import("./../../../src/pages/relocation-service/index.tsx" /* webpackChunkName: "component---src-pages-relocation-service-index-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}


import React, { useState, createContext } from 'react';
import type { SingleValue } from 'react-select';

import { currencies } from '@/utils/currencies';

const mappedCurrencies = currencies.map((curr) => ({
  ...curr,
  value: curr.name,
}));

export type TMappedCurrency = (typeof mappedCurrencies)[number];

export type ICurrencyContext = {
  currencies: typeof mappedCurrencies;
  selectedCurrency: TMappedCurrency | null;
  changeCurrency: (newValue: SingleValue<TMappedCurrency>) => void;
};

export const CurrencyContext = createContext<ICurrencyContext>({
  changeCurrency: (_: SingleValue<TMappedCurrency>) => void {},
  currencies: mappedCurrencies,
  selectedCurrency: mappedCurrencies[0],
});

interface IPricingProviderProps {
  children: React.ReactNode;
}

export const CurrencyProvider: React.FC<IPricingProviderProps> = ({
  children,
}) => {
  const [selectedCurrency, setSelectedCurrency] =
    useState<TMappedCurrency | null>(mappedCurrencies[0]);
  const changeCurrency = (option: TMappedCurrency | null) => {
    setSelectedCurrency(option || null);
  };

  return (
    <CurrencyContext.Provider
      value={{
        currencies: mappedCurrencies,
        selectedCurrency,
        changeCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
